<template>
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-sm-12 text-center">
                <div class="iq-error position-relative mt-5">
                    <h1 class="mb-0">Coming Soon 2024</h1>
                    <button @click="$router.go(-1)" class="btn btn-primary mt-3">
                        <i class="ri-home-4-line"></i>Back to HOMEiZ
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { socialvue } from '@/config/pluginInit'

export default {
  name: 'ErrorPage',
  mounted () {
    socialvue.index()
    let body = document.querySelector('body')
    body.classList.add('iq-bg-primary')
  },
  data () {
    return { }
  }
}
</script>
